var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item-card" }, [
    _c(
      "div",
      { staticClass: "item-card__main", on: { click: _vm.data.click } },
      [
        _c("div", { staticClass: "flex bet a-center" }, [
          _c("div", {
            domProps: {
              textContent: _vm._s(_vm.item.makat + " (" + _vm.$t("SKU") + ")"),
            },
          }),
        ]),
        _c(
          "div",
          {
            staticClass: "item-card__main_main",
            class: { urlImage: _vm.urlImage },
          },
          [
            _vm.urlImage
              ? _c("img", { attrs: { src: _vm.urlImage, alt: "" } })
              : _vm._e(),
            _c("div", { staticClass: "item-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: {
                  textContent: _vm._s(
                    _vm.item.typeOfObj
                      ? _vm.$t("Item") + " " + _vm.itemsType
                      : _vm.$t("Name")
                  ),
                },
              }),
              _c("div", {
                domProps: {
                  textContent: _vm._s(
                    _vm.he ? _vm.item.name : _vm.item.name_en
                  ),
                },
              }),
            ]),
            _c("div", { staticClass: "item-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Branches")) },
              }),
              _c("div", {
                domProps: { textContent: _vm._s(_vm.branchesText) },
              }),
            ]),
          ]
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "item-card__btm" },
      [
        _vm.restaurantCurrencyInfo === null
          ? _c("div", {
              staticClass: "item-card__case red",
              domProps: { textContent: _vm._s("₪" + _vm.item.price) },
            })
          : _vm._e(),
        _vm.restaurantCurrencyInfo !== null
          ? _c("div", {
              staticClass: "item-card__case red",
              domProps: {
                textContent: _vm._s(
                  _vm.restaurantCurrencySymbol + _vm.item.price
                ),
              },
            })
          : _vm._e(),
        _vm.data.active
          ? _c("swich-button", {
              attrs: { value: _vm.valueActive },
              on: { input: _vm.data.active },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }